:root {
    --size: 24%;
    --fg-1: #DDE;
    --fg-2: #bbc;
    --fg-3: #d3d3d3;
    --bg-1: #252b33;
    --bg-2: #0a1018;
    --bg-3: #364046;
    --bg-4: #4F6169;

    --separator: #666c74;
    --selection: yellow;

    --a-fg-1: #FFF;
    --a-fg-2: #467DC0;

    --btn-fg-1: white;
    --btn-fg-2: #eee;
    --btn-bg-1: #4077BA;
    --btn-bg-2: #305A8D;
    --btn-actionCall-bg: rgb(255, 111, 0);
    --btn-border-1: white;
    --btn-border-2: blue;

    --alert-fg-1: white;
    --alert-fg-2: red;
    --alert-bg-1: #dd2222c0;
    --fg-add: white;
    --bg-add: green;
    --fg-ok: #76c576;
    --bg-ok: #239473;
    --fg-cancel: white;
    --bg-cancel: blue;

    --banner-img-filter: none;

    /* --primary: rgba(255, 80, 80, 1);
    --secondary: #ff6100; */

    --btn-sw-border: #d1d5db;
    --btn-sw-cursor: #89909E;
    --btn-sw-checked: #1771e6;
    --btn-sw-checked-cursor: #fff;
    --svg-fg-1: #747474;
}